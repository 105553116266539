// Import jQuery and bind it.
import $ from "jquery";
window.$ = $;

// Import WhatInput.
import whatInput from "what-input";

// Import Foundation and utilities. DO NOT REMOVE.
import { Foundation } from "foundation-sites/js/foundation.core";
Foundation.addToJquery($);
import { MediaQuery } from "foundation-sites/js/foundation.util.mediaQuery";
Foundation.MediaQuery = MediaQuery;

// Uncomment 'import' AND 'Foundation.plugin' for each plugin you do not want to use.
// import { Abide } from 'foundation-sites/js/foundation.abide'
// Foundation.plugin(Abide, 'Abide')

import { Accordion } from "foundation-sites/js/foundation.accordion";
Foundation.plugin(Accordion, "Accordion");

import { AccordionMenu } from "foundation-sites/js/foundation.accordionMenu";
Foundation.plugin(AccordionMenu, "AccordionMenu");

import { Drilldown } from "foundation-sites/js/foundation.drilldown";
Foundation.plugin(Drilldown, "Drilldown");

import { Dropdown } from "foundation-sites/js/foundation.dropdown";
Foundation.plugin(Dropdown, "Dropdown");

import { DropdownMenu } from "foundation-sites/js/foundation.dropdownMenu";
Foundation.plugin(DropdownMenu, "DropdownMenu");

import { Equalizer } from "foundation-sites/js/foundation.equalizer";
Foundation.plugin(Equalizer, "Equalizer");

// import { Interchange } from 'foundation-sites/js/foundation.interchange'
// Foundation.plugin(Interchange, 'Interchange')

// import { Magellan } from 'foundation-sites/js/foundation.magellan'
// Foundation.plugin(Magellan, 'Magellan')

// import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas'
// Foundation.plugin(OffCanvas, 'OffCanvas')

// import { Orbit } from 'foundation-sites/js/foundation.orbit'
// Foundation.plugin(Orbit, 'Orbit')

import { ResponsiveMenu } from "foundation-sites/js/foundation.responsiveMenu";
Foundation.plugin(ResponsiveMenu, "ResponsiveMenu");

import { ResponsiveToggle } from "foundation-sites/js/foundation.responsiveToggle";
Foundation.plugin(ResponsiveToggle, "ResponsiveToggle");
Foundation.ResponsiveToggle.defaults.hideFor = "sm";

import { Reveal } from "foundation-sites/js/foundation.reveal";
Foundation.plugin(Reveal, "Reveal");

import { Slider } from "foundation-sites/js/foundation.slider";
Foundation.plugin(Slider, "Slider");

import { SmoothScroll } from "foundation-sites/js/foundation.smoothScroll";
Foundation.plugin(SmoothScroll, "SmoothScroll");

import { Sticky } from "foundation-sites/js/foundation.sticky";
Foundation.plugin(Sticky, "Sticky");
Foundation.Sticky.defaults.stickyOn = "sm";

import { Tabs } from "foundation-sites/js/foundation.tabs";
Foundation.plugin(Tabs, "Tabs");

import { Toggler } from "foundation-sites/js/foundation.toggler";
Foundation.plugin(Toggler, "Toggler");

import { Tooltip } from "foundation-sites/js/foundation.tooltip";
Foundation.plugin(Tooltip, "Tooltip");
Foundation.Tooltip.defaults.showOn = "base";

import { ResponsiveAccordionTabs } from "foundation-sites/js/foundation.responsiveAccordionTabs";
Foundation.plugin(ResponsiveAccordionTabs, "ResponsiveAccordionTabs");

// Init.
$(function () {
    $(document).foundation();
});

$(document).on("closed.zf.reveal", function () {
    Foundation.reInit("sticky");
});
