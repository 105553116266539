import $ from "jquery";

$(function () {
    if ($(".woocommerce .product-image-slider").length) {
        const galleryThumbs = new window.Swiper(".woocommerce .product-thumbnail-slider", {
            slidesPerView: 5,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: true,
            },
        });

        if ($(".woocommerce .product-thumbnail-slider").length) {
            const galleryTop = new window.Swiper(".woocommerce .product-image-slider", {
                autoHeight: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                thumbs: {
                    swiper: galleryThumbs,
                },
            });
        }
    }
});

$(document).on("change input", ".woocommerce-cart-form .cart_item :input", function (e) {
    $('.woocommerce-cart-form :input[name="update_cart"]').prop("disabled", false);
});

$(document).on('click', '.plus', function (e) {
    const $input = $(this).siblings('input.qty');
    console.log($input);
    const $inputName = $input.attr('name');
    const $inputID = $input.attr('id');
    // Search for second input with the same name to work around correctly updating the cart.
    const $secondInput = $(`.woocommerce-cart input[name='${$inputName}']:not([id='${$inputID}'])`);
    const max = parseInt($input.attr('max'));
    const val = parseInt($input.val());
    let step = $input.attr('step');
    step = 'undefined' !== typeof step ? parseInt(step) : 1;
    if (isNaN(max) || val < max) {
        $input.val(val + step).change();
        if ($secondInput.length !== 0) {
            $secondInput.val(val + step).change();
        }
    }
});
$(document).on('click', '.minus', function (e) {
    const $input = $(this).siblings('input.qty');
    const $inputName = $input.attr('name');
    const $inputID = $input.attr('id');
    // Search for second input with the same name to work around correctly updating the cart.
    const $secondInput = $(`.woocommerce-cart input[name='${$inputName}']:not([id='${$inputID}'])`);
    const val = parseInt($input.val());
    let step = $input.attr('step');
    step = 'undefined' !== typeof step ? parseInt(step) : 1;
    if (val > 0) {
        $input.val(val - step).change();
        if ($secondInput.length !== 0) {
            $secondInput.val(val - step).change();
        }
    }
});
